<template>

  <div>
    <v-dialog max-width="500px" v-model="show3DSecureDialog" persistent>
        <v-card>
          <iframe
            seamless
            style="border: 0;"
            width="100%"
            height="500px"
            :srcdoc="rawHTMLFor3DSecure"
          />
        </v-card>
      </v-dialog>

    <v-dialog
      :persistent="processing"
      max-width="500"
      :value="true"
      @click:outside="close"
    >

      <v-card>

        <v-card-title>{{ $t('purchaseCredits') }}</v-card-title>

        <v-card-subtitle>{{ $t('specifyHowMuchToAddToYourBalance') }}</v-card-subtitle>

        <v-card-text>{{ $t('minimumAmountIs5000ISKMaximumAmountIs100000ISK') }}</v-card-text>

        <v-card-text class="pb-0">
          <PaymentMethodInstanceUIForm :payment-method-instance="paymentMethodInstance" />
        </v-card-text>

        <v-card-text>
          <v-text-field
            hide-details
            filled
            suffix="ISK"
            autofocus
            :label="$t('amount')"
            type="number"
            v-model="amount"
          />
        </v-card-text>

        <v-card-text v-if="paymentServiceRequestResponse !== null && paymentServiceRequestResponse !== undefined && paymentServiceRequestResponse.paymentTransaction !== null && paymentServiceRequestResponse.paymentTransaction !== undefined && paymentServiceRequestResponse.paymentTransaction.status === 'failure' && (paymentServiceRequestResponse.paymentTransaction.payload !== null && paymentServiceRequestResponse.paymentTransaction.payload !== undefined && paymentServiceRequestResponse.paymentTransaction.payload.mdStatus !== null && paymentServiceRequestResponse.paymentTransaction.payload.mdStatus !== undefined && paymentServiceRequestResponse.paymentTransaction.payload.mdStatus !== '1')">
          <v-alert
            type="error"
            :dismissible="true"
          >
            {{ paymentServiceRequestResponse.paymentTransaction.message }}
          </v-alert>
        </v-card-text>

        <v-card-text v-if="paymentServiceRequestResponse !== null && paymentServiceRequestResponse !== undefined && paymentServiceRequestResponse.paymentTransaction !== null && paymentServiceRequestResponse.paymentTransaction !== undefined && paymentServiceRequestResponse.paymentTransaction.status === 'success'">
          <v-alert
            type="success"
            :dismissible="true"
          >
            {{ paymentServiceRequestResponse.paymentTransaction.message }}
          </v-alert>
        </v-card-text>
        

        <v-card-text>
          {{ $t('estimatedNumberSmsMessages', {number: estimatedSMSMessages}) }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="processing || !purchaseCanBeMade"
            :loading="processing"
            block
            large
            color="success"
            @click="purchase"
          >
            {{ $t('purchase') }}
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import PaymentMethodInstanceUIForm from '../paymentMethodInstance/PaymentMethodInstanceUIForm'

export default {
    name: 'BillingCreditDialog',
    components: {
        PaymentMethodInstanceUIForm
    },
    data() {
        return {
            processing: false,
            amount: 5000,
            paymentServiceRequestResponse: null,
            show3DSecureDialog: false,
            smsCost: 13
        }
    },
    watch: {
        paymentServiceRequestResponse(value) {
          if (
            value !== null &&
            value !== undefined &&
            value.paymentTransaction !== null &&
            value.paymentTransaction !== undefined &&
            value.paymentTransaction.payload !== null &&
            value.paymentTransaction.payload !== undefined &&
            value.paymentTransaction.payload["3DSecureHTML"] !== undefined &&
            value.paymentTransaction.payload["3DSecureHTML"] !== undefined &&
            value.paymentTransaction.status === "pending"
          ) {
            setTimeout(() => {
              this.show3DSecureDialog = true
            }, 2500)

            const payload = {
              organizationUUID: "f95bbe80-b9c2-40eb-943d-95d696c1acfb",
              channelUUID: "6a7fd054-57a9-4c39-96d5-594d71eaa932",
              paymentInstanceUUID: this.paymentMethodInstance.uuid,
              orderUUID: value.paymentTransaction.uuid
            }

           this.checkPendingPayment(payload)

          } else if (
            value !== null &&
            value !== undefined &&
            value.paymentTransaction !== null &&
            value.paymentTransaction !== undefined &&
            value.paymentTransaction.payload !== null &&
            value.paymentTransaction.payload !== undefined &&
            value.paymentTransaction.payload.mdStatus !== null &&
            value.paymentTransaction.payload.mdStatus !== undefined &&
            value.paymentTransaction.payload.mdStatus === "1"
          ) {
            this.finalize(value.entangleKey)
          }
          else if (
            value !== null &&
            value !== undefined &&
            value.paymentTransaction !== null &&
            value.paymentTransaction !== undefined &&
            value.paymentTransaction.payload !== null &&
            value.paymentTransaction.payload !== undefined &&
            value.paymentTransaction.payload.mdStatus !== null &&
            value.paymentTransaction.payload.mdStatus !== undefined &&
            value.paymentTransaction.payload.mdStatus !== "1" && 
            value.paymentTransaction.status === 'failure' 
          ) {
            this.show3DSecureDialog = false
            this.processing = false
          }
        }
    },
    methods: {
        checkPendingPayment(payload) {

          setTimeout(() => {
            this.$store.dispatch('checkPendingPayment', payload).then(newPayment => {
              if (newPayment && (newPayment.paymentTransaction && newPayment.paymentTransaction.status === 'pending' || newPayment.paymentTransaction === null)) {
                return this.checkPendingPayment(payload)
              } 
              else if (!newPayment) {
                return this.checkPendingPayment(payload)
              }
              else {
                  this.paymentServiceRequestResponse = newPayment
                  return
                }
              })
            }, 5000)
        },

        close() {
            if(this.processing) {
                return
            }
            this.$emit('closed')
        },

        finalize(entangleKey) {
          const details = this.paymentMethodInstanceDetails

          details.skipCardVerification = true

          details.cavv = this.paymentServiceRequestResponse.paymentTransaction.payload.cavv
          details.xid = this.paymentServiceRequestResponse.paymentTransaction.payload.xid
          details.mdStatus = this.paymentServiceRequestResponse.paymentTransaction.payload.mdStatus
          details.dsTransId = this.paymentServiceRequestResponse.paymentTransaction.payload.dsTransId

          const request = {
            billingInfo: {
              name: this.$store.state.organization.label,
              email: this.$store.state.organization.email,
              phone: this.$store.state.organization.phone
            },
            items: [],
            entangleKey,
            namespace: this.paymentMethodInstance.namespace,
            amount: this.amount,
            currency_code: 'ISK',
            details
          }

          this.$store.dispatch('processPaymentRequest', {

            paymentRequest: request,
            paymentMethodInstanceUUID: this.paymentMethodInstance.uuid

          }).then(paymentRequestResponse => {

            this.paymentServiceRequestResponse = paymentRequestResponse

          }).finally(() => {
            this.processing = false
            this.show3DSecureDialog = false
            this.$emit('purchaseCompleted', this.paymentServiceRequestResponse)
          })
        },

        purchase() {

            this.processing = true
            this.$store.dispatch('processPaymentRequest', {

                paymentRequest: this.paymentServiceRequest,
                paymentMethodInstanceUUID: this.paymentMethodInstance.uuid

            }).then(paymentRequestResponse => {

                this.paymentServiceRequestResponse = paymentRequestResponse

            }).finally(() => {

              if (this.paymentServiceRequestResponse && this.paymentServiceRequestResponse.paymentTransaction && this.paymentServiceRequestResponse.paymentTransaction.status !== 'pending') {
                this.processing = false
              }

              if (!this.paymentServiceRequestResponse || !this.paymentServiceRequestResponse.paymentTransaction) {
                this.processing = false
              }
            })
        }
    },
    computed: {
      rawHTMLFor3DSecure() {
        if (
          this.paymentServiceRequestResponse !== null &&
          this.paymentServiceRequestResponse !== undefined &&
          this.paymentServiceRequestResponse.paymentTransaction !== null &&
          this.paymentServiceRequestResponse.paymentTransaction !== undefined &&
          this.paymentServiceRequestResponse.paymentTransaction.payload !== null &&
          this.paymentServiceRequestResponse.paymentTransaction.payload !== undefined &&
          this.paymentServiceRequestResponse.paymentTransaction.payload["3DSecureHTML"] !== undefined &&
          this.paymentServiceRequestResponse.paymentTransaction.payload["3DSecureHTML"] !== undefined
          ){
            return decodeURIComponent(this.paymentServiceRequestResponse.paymentTransaction.payload["3DSecureHTML"])
          }

          return null
        },
        purchaseCanBeMade() {

            if(this.amount < 10 || this.amount > 100000) {
                return false
            }

            return true
            
        },
        estimatedSMSMessages() {

          return Math.floor(this.amount / this.smsCost)

        },
        itemToPurchase() {
          
            return {
                uuid: '9b7c02d8-3355-4d7e-8742-443596b2864d',
                quantity: 1,
                price: {
                    amount: Number(this.amount),
                    currency_code: 'ISK'
                },
                comment: this.$store.state.organizationUUID
            }

        },
        paymentMethodInstanceDetails() {
            const details = {}

            for(let i in this.paymentMethodInstance.uiFields) {
                const uiField = this.paymentMethodInstance.uiFields[i]
                details[uiField.key] = uiField.value
            }

            return details
        },
        paymentMethodInstance() {
            return this.$store.state.platformPaymentMethodInstances.find(item => item.namespace === 'valitor_pay')
        },
        paymentServiceRequest() {

            return this.$store.getters.paymentServiceRequestForSinglePlatformItem(

                this.itemToPurchase,
                this.paymentMethodInstance.namespace,
                this.amount,
                'ISK',
                this.paymentMethodInstanceDetails

            )

        }
    }
}
</script>