<template>
  <div>
    <BillingCreditDialog
      v-if="billingCreditDialog"
      @closed="billingCreditDialogClosed"
      @purchaseCompleted="billingCreditPurchaseCompleted"
    />
    <v-card>
      <v-card-title>{{ $t('credits') }}</v-card-title>
      <v-card-subtitle>{{ $t('creditsAreUsedToFundOtherRealtimeUses') }}</v-card-subtitle>
      <v-card-text>
        <div class="mb-3">
          <div class="caption">
            {{ $t('CURRENTCREDITS') }}
          </div>
          <div
            class="headline green--text"
            v-if="credits.amount > -1"
            style="font-weight: bolder;"
          >
            {{ formattedCreditsAmount }}
          </div>
          <small>Est. {{ estimatedSMSMessagesLeft }} sms messages.</small>
        </div>
        <div class="caption">
          {{ $t('AUTORECHARGE') }}
        </div>
        <div
          class="headline orange--text"
          style="font-weight: bold;"
        >
          {{ $t('NOTENABLED') }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="flex"
          color="success"
          @click="addFunds"
        >
          {{ $t('addFunds') }}
        </v-btn>
        <v-btn
          disabled
          color="green"
        >
          {{ $t('enableAutoRecharge') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import BillingCreditDialog from './BillingCreditDialog'

export default {
    name: 'BillingCredit',
    components: {
        BillingCreditDialog
    },
    data() {
        return {
            billingCreditDialog: false,
            smsCost: 13,
        }
    },
    computed: {
      formattedCreditsAmount() {
        if(typeof this.credits !== 'undefined' && this.credits !== null) {
          return this.$store.getters.formatAmountByCurrency(this.credits)
        }
        return ''
      },
        credits() {

            if(this.$store.state.organization.credits !== null && this.$store.state.organization.credits !== undefined) {
              return this.$store.state.organization.credits
            }

            return {
                amount: 0,
                currency_code: 'ISK'
            }
        },
        estimatedSMSMessagesLeft() {

          if(this.credits.amount === 0) {
            return 0
          }

          return Math.floor(this.credits.amount / this.smsCost)
        }
    },
    methods: {
        billingCreditPurchaseCompleted() {
            this.$store.dispatch('getInitialData').finally(() => {
              this.billingCreditDialog = false
            })
        },
        billingCreditDialogClosed() {
            this.billingCreditDialog = false
        },
        addFunds() {
            this.billingCreditDialog = true
        }
    }
}
</script>