<template>
  <v-form>
    <v-row>
      <template v-for="(uiField, index) in paymentMethodInstance.uiFields">
        <v-col
          class="py-0"
          :key="uiField.key"
          :cols="uiField.cols"
        >
          <uiField v-model="paymentMethodInstance.uiFields[index]" />
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>

import uiField from '../uiField'

export default {
    name: 'PaymentMethodInstanceUIForm',
    components: {
        uiField
    },
    props: {
      paymentMethodInstance: {
        type: Object, 
        default: () => {}
      }
    }
}
</script>